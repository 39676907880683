import styled from 'styled-components';

export const StyledList = styled.ol`
    list-style: none;
    padding-left: 0px;
    max-width: 900px;
    padding-top: 100px;
    margin: 0 auto;
`;

export const StyledArticle = styled.article`
    display: flex;
    flex-direction: column;
`;

export const StyledListItem = styled.li`
    margin-bottom: 90px;
`;

export const StyledSection = styled.section`
    padding-top: 20px;
`;
