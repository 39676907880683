import styled from 'styled-components';

export const StyledList = styled.ol`
    list-style: none;
    padding-left: 0px;
    margin-top: 40px;
`;

export const StyledBlogWrapper = styled.div`
    position: relative;
    height: 100%;
    z-index: ${({ theme }) => theme.zIndex[1]};
    background: radial-gradient(
            62.27% 39.64% at 75.91% 100%,
            #acc9e5 0%,
            rgba(172, 201, 229, 0) 100%
        ),
        radial-gradient(
            108.95% 90.66% at 28.93% 150.68%,
            #003e6b 20.31%,
            rgba(235, 242, 246, 0.22) 100%
        );
`;

export const StyledBlogContentWrapper = styled.div`
    padding-bottom: 50px;
    min-height: 1100px;
`;
