import React, { useState } from 'react';

import { useToggle } from 'hooks';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { StyledLayout } from 'components/Layout';
import {
    DottedBackground,
    PatternPositions,
    PatternVariants,
} from 'components/DottedBackground';
import { StyledForeground } from 'components/Foreground';
import { ThemedPage } from 'templates/Page';
import { ConfirmationModalContent } from 'templates/ConfirmationModalContent';
import { ContactModalContent } from 'templates/ContactModalContent';
import { Modal } from 'components/Modal';
import { CommunityModalContent } from 'templates/CommunityModalContent';
import { ModalContents } from 'enums';
import {
    StyledBlogContentWrapper,
    StyledBlogWrapper,
} from './satelliteComponents';

type TBlogTemplate = {
    children: React.ReactNode;
};

export function BlogTemplate({ children }: TBlogTemplate) {
    const [isModalOpened, toggleIsModalOpened] = useToggle(false);
    const [modalContent, setModalContent] = useState<ModalContents>(
        ModalContents.CONTACTUS
    );

    const handleOpenModal = (content: ModalContents) => {
        setModalContent(content);
        if (!isModalOpened) {
            toggleIsModalOpened();
        }
    };

    return (
        <ThemedPage handleOpenModal={handleOpenModal}>
            <StyledBlogWrapper>
                <StyledBlogContentWrapper>
                    <DottedBackground
                        placements={{
                            [PatternPositions.TOP_LEFT]:
                                PatternVariants.LIGHT_BG,
                            [PatternPositions.BOTTOM_RIGHT]:
                                PatternVariants.LIGHT_BG,
                            [PatternPositions.BOTTOM_LEFT]:
                                PatternVariants.LIGHT_BG,
                        }}
                    />
                    <StyledLayout>
                        <StyledForeground>
                            <Header handleOpenModal={handleOpenModal} />
                            {children}
                        </StyledForeground>
                    </StyledLayout>
                </StyledBlogContentWrapper>
                <Footer handleOpenModal={handleOpenModal} />
            </StyledBlogWrapper>
            <Modal visible={isModalOpened} handleClose={toggleIsModalOpened}>
                {modalContent === ModalContents.CONTACTUS && (
                    <ContactModalContent handleOpenModal={handleOpenModal} />
                )}
                {modalContent === ModalContents.COMMUNITY && (
                    <CommunityModalContent handleOpenModal={handleOpenModal} />
                )}
                {modalContent === ModalContents.CONFIRMATION && (
                    <ConfirmationModalContent />
                )}
            </Modal>
        </ThemedPage>
    );
}
