import * as React from 'react';

import { StyledLink } from 'components/Link';
import { StyledTitle } from 'components/Title';
import { HeadingTypes } from 'components/Title/Title';
import { FancyHighlight } from 'components/FancyHighlight';
import { ParagraphTypes, StyledParagraph } from 'components/Paragraph';
import {
    StyledList,
    StyledListItem,
    StyledSection,
    StyledArticle,
} from './satelliteComponents';
import { BlogTemplate } from './BlogTemplate';

export type MarkdownNode = {
    excerpt: string;
    fields: {
        slug: string;
    };
    frontmatter: {
        date: string;
        title: string;
        description: string;
    };
};

export type TData = {
    site: {
        siteMetadata: {
            title: string;
        };
    };
    allMarkdownRemark: {
        nodes: MarkdownNode[];
    };
};

type TBlogPage = {
    data: TData;
};

export function BlogPage({ data }: TBlogPage) {
    const posts = data.allMarkdownRemark.nodes;

    return (
        <BlogTemplate>
            <StyledList>
                {posts.map((post) => {
                    const title = post.frontmatter.title || post.fields.slug;

                    return (
                        <StyledListItem key={post?.fields?.slug}>
                            <StyledArticle>
                                <StyledLink
                                    internal
                                    to={post?.fields?.slug}
                                    textColor={({ theme }) =>
                                        theme.palette.blue['60']
                                    }
                                >
                                    <StyledTitle variant={HeadingTypes.H2}>
                                        <FancyHighlight fancy>
                                            {title}
                                        </FancyHighlight>
                                    </StyledTitle>
                                </StyledLink>
                                <StyledParagraph
                                    margin="4px 0 0 0"
                                    variant={ParagraphTypes.T12}
                                >
                                    {post.frontmatter.date}
                                </StyledParagraph>
                                <StyledSection>
                                    <StyledParagraph
                                        variant={ParagraphTypes.T18}
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                post.frontmatter.description ||
                                                post.excerpt,
                                        }}
                                    />
                                </StyledSection>
                            </StyledArticle>
                        </StyledListItem>
                    );
                })}
            </StyledList>
        </BlogTemplate>
    );
}
