import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { BlogPage } from 'templates/BlogPage';
import { TData } from 'templates/BlogPage/BlogPage';

function IndexBlog({ data }: PageProps<TData>) {
    const posts = data.allMarkdownRemark.nodes;
    if (posts.length === 0) {
        return 'no posts';
    }

    return <BlogPage data={data} />;
}

export default IndexBlog;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                }
            }
        }
    }
`;
